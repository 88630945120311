<template>
  <div>
    <custom-table
      ref="customTable"
      :columns="columns"
      :custom-view="customView"
      :footer-actions="footerActions"
      :sums="sums"
      table-height="calc(100vh - 145px)"
      api-url="/physical-combo-program-list"
      @showDialogCreate="showDialogCreate"
      @showEditProgram="showEditProgram"
      @showDetail="showDetail"
      @cancelProgram="cancelProgram"
    ></custom-table>

    <v-dialog v-model="dialogCreateUpdate" persistent max-width="400px">
      <PhysicalComboProgramCreate
        v-if="dialogCreateUpdate"
        :update-item="updateItem"
        :is-detail-show="isDetailShow"
        @close="hideDialogCreate"
        @refreshData="refreshCustomTable"
      />
    </v-dialog>
  </div>
</template>

<script>
import {
  CUSTOM_VIEW_PHYSICAL_COMBO_LIST,
  PHYSICAL_COMBO_STATUS,
} from "@/libs/const";
import { httpClient } from "@/libs/http";
export default {
  name: "Present",
  components: {
    PhysicalComboProgramCreate: () =>
      import("@/components/goods/PhysicalComboProgramCreate"),
  },
  data: () => ({
    customView: CUSTOM_VIEW_PHYSICAL_COMBO_LIST,
    columns: [],
    footerActions: [],
    sums: [],
    isLoading: false,
    statusOptions: [...PHYSICAL_COMBO_STATUS],
    dialogCreateUpdate: false,
    isDetailShow: false,
    updateItem: {},
  }),
  computed: {},
  created() {
    const columns = [
      {
        type: "date-range-filter",
        label: this.$t("labels.create_time"),
        placeholder: this.$t("labels.create_time"),
        name: "created_at",
        hasSort: true,
        sortName: "created_at",
        key: "created_at",
        required: true,
      },
      {
        type: "select-employee",
        label: this.$t("labels.creator_name"),
        placeholder: this.$t("labels.creator_name"),
        name: "id_identity",
        hasSort: true,
        sortName: "id_identity",
        key: "identity_name",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.tracking"),
        placeholder: this.$t("labels.tracking"),
        name: "tracking_id",
        hasSort: true,
        sortName: "tracking_id",
        key: "tracking_id",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.program_name"),
        placeholder: this.$t("labels.program_name"),
        name: "name",
        hasSort: true,
        sortName: "name",
        key: "name",
        required: true,
      },
      {
        type: "date-range-filter",
        label: this.$t("labels.start_time"),
        placeholder: this.$t("labels.start_time"),
        name: "start_time",
        hasSort: true,
        sortName: "start_time",
        key: "start_time",
        required: true,
      },
      {
        type: "date-range-filter",
        label: this.$t("labels.end_time"),
        placeholder: this.$t("labels.end_time"),
        name: "end_time",
        hasSort: true,
        sortName: "end_time",
        key: "end_time",
        required: true,
      },
      {
        type: "select-filter",
        label: this.$t("labels.status_1"),
        placeholder: this.$t("labels.status_1"),
        name: "status",
        hasSort: true,
        sortName: "status",
        key: "status",
        options: this.statusOptions,
        required: true,
      },
      {
        type: "button",
        label: "",
        headerAction: "showDialogCreate",
        headerActionColor: "primary",
        headerActionLabel: this.$t("labels.add"),
        action: false,
        required: true,
        buttonActions: [
          {
            action: "cancelProgram",
            text: this.$t("labels.cancel"),
            color: "error",
            class: "",
            permissions: [],
            modules: [],
            allowKey: "can_cancel",
          },
          {
            action: "cancelProgram",
            text: this.$t("labels.stop"),
            color: "error",
            class: "",
            permissions: [],
            modules: [],
            allowKey: "can_stop",
          },
        ],
      },
    ];
    this.columns = [...columns];

    const footerActions = [];
    this.footerActions = [...footerActions];

    const sums = [];
    this.sums = [...sums];
  },
  mounted() {},
  methods: {
    refreshCustomTable() {
      this.$refs.customTable.getList();
    },
    showDialogCreate() {
      this.updateItem = {};
      this.dialogCreateUpdate = true;
    },
    hideDialogCreate() {
      this.updateItem = {};
      this.dialogCreateUpdate = false;
      this.isDetailShow = false;
    },
    showEditProgram(item) {
      this.updateItem = { ...item };
      this.dialogCreateUpdate = true;
    },
    showDetail(item) {
      this.isDetailShow = true;
      this.showEditProgram(item);
    },
    async cancelProgram(item) {
      if (!confirm(this.$t("messages.are_you_sure_confirm"))) {
        return false;
      }
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/physical-combo-program-cancel", item);
        this.isLoading = false;
        this.$vToastify.success(this.$t("messages.update_success"));
        this.refreshCustomTable();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
